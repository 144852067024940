.verify-otp-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:transparent;
  height: 100vh;
  width: 100%;
}
.background-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background:linear-gradient(to right, #20041C, #391C72); */
}
.back-button {
 
  top: -7rem;
  left: 20px;
}
.icon {
  width: 24px;
  height: 24px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  gap: 0.6rem;
  text-align: center;
  text-wrap: wrap;
  padding: 2rem ;
  width: 27rem;
  min-height: 25rem;
  border: 1px solid #cccccca0;
  text-wrap: wrap;
 h1{
  text-wrap: wrap;
  text-align: center;
  font-family: var(--poppins);
  margin-bottom: 0;
 }
 p{
  font-family: var(--lato);
  color:  #BDBDBD;
  font-size: 1.1rem;
font-style: normal;
font-weight: 400;
line-height: 1.375rem; /* 91.667% */
letter-spacing: -0.02563rem;
 }
 h3{
  font-style: normal;
font-weight: 400;
line-height: 1.375rem; /* 91.667% */
letter-spacing: -0.02563rem;
color:  #BDBDBD;
 }
 button{
  width: 100% !important;

  height: 3rem;
  font-size: 1rem;
font-style: normal;
font-weight: 700;
font-family: var(--lato);
 }
}
.title {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.subtitle {
  color: #ddd;
  font-size: 16px;
  margin-bottom: 30px;
}
.otp-input {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.otp-input-field {
  width: 60px;
  height: 60px;
  margin: 10px;
  text-align: center;
  font-size: 30px;
  border: 1px solid #aaa;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.login-button {
  /* background-image: url('./css_asset/btnbg.png'); */
  background: linear-gradient(90deg, #a903d2, #410095);
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
width:18rem !important;
height: 40px;
font-family: var(--poppins);
display: flex;
justify-content: center;
align-items: center;
width: 100%;
border: none;
cursor: pointer;
color: #fff;
font-size: 16px;
margin-bottom: 20px;
}
.login-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.loader {
  border: 4px solid #F3F3F3;
  border-top: 4px solid #3498DB;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.abs-ac{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 6.25rem;
  position: absolute;
  top:0%;
}
@media screen and (max-width: 768px) {
  .background-image{
   padding: 0 2rem ;
    box-sizing: border-box;
  }
  .content{
   width: 100%;
   padding: 0 1rem;
  }
  .background-image {
    box-sizing: border-box;
  }
  .abs-ac{
    padding: 0 2rem;
    top:2rem;
  }
}
.margin{
  margin-top: -1rem;
  box-sizing: border-box;
}
.margin:hover{
  border-bottom: 1px solid white;
  transition: 0.5s ease;
}