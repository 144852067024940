.carousel-container {
    
    position: relative;
    width: 100%;
    max-width: 800px; /* Set your preferred width */
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.carousel-wrapper {
    display: flex;
    transition: transform 0.4s ease-in-out;
}

.carousel-item {
    width: 12rem; 
    height: 9rem;
    border-radius: 10px;
    transition: transform 0.2s linear, opacity 0.2s linear; 
    opacity: 0.1;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.carousel-item.activee {
    opacity: 1; /* Full opacity for active item */
   scale:1.8;
   transition: 0.2s ease-in;
   cursor:url(./css_asset/newpointer.png), auto;
   background-color: none !important;
}

.carousel-item img {
    width: 100% !important;
    border-radius: 10px;
    height: 100% !important;
}

.carousel-frame {
    position: absolute;
    top: 36% !important;
    left: 50%;
    width: 25rem !important; /* Adjust based on your frame's size */
    height: 500px; /* Adjust based on your frame's size */
    transform: translate(-70%, -50%);
    z-index: 10;
    margin-bottom: 10% !important;
}

.frame {
    width: 100% !important;
    height: 100% !important;
   
}