@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Orbitron:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Scope+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Orbitron:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Scope+One&display=swap');

:root {
  --lato: "Lato", sans-serif;
  --poppins: "Poppins", sans-serif;
}
body{
  background:linear-gradient(to right, #20041c, #000);
  /* user-select: none;
  -webkit-user-select: none; */

  color: #FFFFFF;
  overflow-x: hidden;
  width: 100%;
  cursor:url(./images/newcur.png), auto;
 
}
a {
  text-decoration: none;
  font-family: var(--lato); /* Use the variable for Lato */
  font-size: 18px;
  color: #FFFFFF;
  font-weight: bold;
  
}
a:hover{
  scale:1.1;
  transition: 0.2s ease;
  cursor:url(./images/newpointer.png), auto;
}
button{
  cursor: pointer;
  cursor:url(./images/newpointer.png), auto;
}