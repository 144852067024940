.main-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
  backdrop-filter: blur(10px);
}
body{
  /* background: url('../assets/dashboardbg.png') no-repeat center center/cover; */
}
.main-super-profile{
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
 
}
.profile-container {
  display: flex;
  width: 80%;
  max-width: 1000px;
  margin: 20px auto;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.profile-info, .profile-options {
  width: 50%;
  padding: 20px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(22, 21, 21, 0.5);
  backdrop-filter: blur(10px);
  border-right: 1px solid rgba(37, 31, 31, 0.603);
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-options ul {
  list-style: none;
  padding: 0;
}

.profile-options li {
  padding: 15px;
  margin: 10px 0;
  background: rgba(22, 21, 21, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s, transform 0.3s;
}

.profile-options li:hover {
  background: rgba(255, 255, 255, 0.7);
  transform: scale(1.05);
  color: #000;
}
