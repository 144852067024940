
.main-pro{
    /* background:linear-gradient(to right, #20041c, #391c72); */
    width: 100%;
    overflow: hidden;
 
}

.button-div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* width: 100%; */
    margin: 1rem 0;
   
}
.button-contain-1{

 display: flex;
 flex-direction: column;
   align-items: center;
   justify-content: center;
 
}
.flex-between{
    
}
.btn-d{
    border: none;
    width: 22.5625rem;
    display: flex;
    align-items: center;
    background-color:rgba(0, 0, 0, 0.527);
    padding:0.2rem 1rem;
    border-radius: 10px;
    box-sizing: border-box;
    color: white;
    gap: 16px;
    img{
        mix-blend-mode:normal;
        width: 50px;
        height: 50px;
    }
}
.line{
    margin-bottom: 1rem;
}
.flex-wrapper{
    width: 100%;
    display: flex;
    align-items: start;
    overflow: hidden;
   padding: 0 10%;
   box-sizing: border-box;
   margin-top: 4rem;
   margin-bottom: 3rem;
   padding: 4rem 6.25rem;
   padding-left: 10%;
   box-sizing: border-box;
   min-height: 80vh;
   gap:7rem;
 overflow: hidden;
}
.select-btn{
   border: 1px solid white;
}
.log{
  background-position:  center;
  width: 22.5625rem;
  /* background-image: url(./css_asset/logoutbg.png); */
  background: linear-gradient(90deg, #a903d2, #410095);;
  height: 3.25rem;
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  color: white;
  border-radius: 10px;

}
.verticall{
    transform: rotate(90deg);
    transform-origin: left ;
   
}
.new-one{
  
    width: max-content;
}
.comp{
    background-color: red;
}

@media screen and (max-width:1195px){
    .flex-wrapper{
        justify-content: center;
        align-items: center;
     gap: 2rem;
     flex-wrap: wrap;
     padding: 0 10rem;
     box-sizing: border-box;
    }
}
@media screen and (max-width: 420px){

}