.super-main-wallet {
    display: flex;
    gap: 1rem;

}

.new-one {

    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.wallet-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.wallet-detail {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;


}

.available-blc {
    width: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 0.625rem;
    padding: 0.5rem 0;
    box-sizing: border-box;
    background-color: #cccccc66;
    border-radius: 10px;

    h1 {
        font-style: italic;

    }
}

.big-g {
    font-size: 25px;
}

.width {
    width: 10rem;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    border-radius: 10px;
    align-items: start;
    padding: 1rem;
    box-sizing: border-box;

    img {
        width: 30px;
        height: 30px;
    }

    p {
        font-size: 14px;
        font-weight: lighter;
    }

}

.total-wins {
    background-image: url(./css_asset/widimg.png);
}

.total-wid {
    background-image: url(./css_asset/widimg2.png);
}

.mix {
    h2 {
        font-family: var(--poppins);
        color: rgba(255, 255, 255, 0.42);
    }
}

.account-offers {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap !important;

}

.quick-action {
    width: 25rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    p {
        font-family: var(--lato);
        font-size: 20px;

    }
}

.small-logos-div {
    width: 100%;
    display: flex;
    border: 1px solid #cccccc6b;
    background-color: #cccccc18;
    border-radius: 10px;
    padding: 1.7rem;
    box-sizing: border-box;
    justify-content: space-between;
}

.small-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 32px;
        height: 32px;
    }

    p {
        font-size: 14px;
    }
}

.small-div:hover {
    scale: 1.1;
    transition: 0.2s ease;
}

.acc-num {
    width: 90%;
    border: 1px solid #cccccc6b;
    background-color: #00000080;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
        font-size: 16px;
    }

}

.custom-hr {
    border: none;
    height: 1px;
    background-color: #cccccc7a;
}

.upi-div {
    width: 90%;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #cccccc6b;
    background-color: #00000080;
    border-radius: 10px;
}

.acc-btn {
    width: 90%;
    background-image: linear-gradient(90deg, #a903d2, #410095);
    border: none;
    background-position: center;
    color: white;
    height: 3.25rem;
    background-color: transparent;
    font-size: 1.2rem;
    border-radius: 10px;
}


.promo {
    width: 25rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    p {
        font-family: var(--lato);


    }
}

.today-offers1 {
    width: 100%;
    padding: 2rem;
    border: 1px solid #cccccc6b;
    background-color: #000000be;
    border-radius: 15px;
    text-wrap: wrap;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

}

.small-width {
    width: 10rem;
    text-align: center;
    font-size: 1.25rem
}

.setsize {
    width: 12rem;
    font-size: 10px;
    text-wrap: wrap;
    text-align: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay-content {
    padding: 20px;
    border-radius: 8px;
    position: relative;
    border-radius: 0.625rem;
    border: 1px solid #000;
    background: #121212;
    box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.00);
    backdrop-filter: blur(25px);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

@media screen and (max-width: 1335px) {
    .wallet-detail {
        flex-direction: column;
        gap: 1rem;

        .w-line {
            transform: rotate(90deg);
            margin: -5rem 0;
        }
    }

    .account-offers {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}

@media screen and (max-width: 768px) {
    .wallet-main {
        width: 100%;

    }

    .available-blc {
        width: 70%;
    }

    .acc-btn {
        width: 100%;
    }

    .wallet-detail {
        flex-direction: column;
        padding: 0 10rem !important;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .w-line {
            transform: rotate(90deg);
            margin: -5rem 0;
        }
    }

    .account-offers {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .quick-action {
        padding: 0 1.5rem;
        box-sizing: border-box;
    }

    .promo {
        padding: 0 1.5rem;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }

}