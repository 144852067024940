.mainView2 {

    padding-bottom: 100px;
    position: relative;

    display: flex;
    flex-direction: column;
   
  }
  
  .backgroundStyle {
    width: 100%;
    height: 80%;
    position: absolute;
    
    background-size: cover;
  }
  
  .safeArea {
    padding: 16px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1.5rem;
  }
  
  .headerContainer {
    width: 100%;
    display: flex;
    align-items: center !important;

  align-items: center;
  justify-content: center;
   
  }
  
  .backButton {
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .icon {
    width: 32px;
    height: 32px;
  }
  
  .header {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    flex: 1;
    margin-left: -30px; /* Adjust to keep centered */
  }
  
  .itemContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;
    box-sizing: border-box;
    margin-bottom: 8px;
    border-radius: 8px;
    width: 100%;
  }
  .backlogo-heading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .tran_cont {
    display: flex;
    justify-content: space-between;
  }
  
  .credit {
    background-color: rgba(255, 195, 0, 0.12);
  }
  
  .debit {
    background-color:rgba(0, 255, 137, 0.09);;
  }
  
  .text {
    color: #fff;
    font-size: 16px;
    display: flex;
justify-content: center;
    flex-direction: column;
    font-size: 1.2rem;
  }
  
  .dateText {
    color: #aaa;
    font-size: 14px;
  }
  
  .loadingText {
    color: #fff;
    font-size: 18px;
    text-align: center;
  }
  
  .listContent {
    padding: 16px 0;
    width: 55vw;
  }
  .inr-div{
    width: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: #00000062;
    padding: 0.5rem;
    box-sizing: border-box;
    gap: 0.5rem;
  }
  .absolute{
    position: relative;
    top: 4%;
    right: 20vw;
  }
  @media screen and (max-width: 768px){
    .listContent{
      width: 90vw;
    }
  }
  