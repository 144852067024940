/* JoinRoomScreen.css */

.container {
    background-color: #000;
    padding: 16px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .backButton {
    align-self: flex-start;
    margin: 20px 0 10px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 32px;
  }
  
  .inputContainer {
    margin-bottom: 16px;
    width: 100%;
  }
  
  .label {
    color: #fff;
    margin-bottom: 8px;
    display: block;
  }
  
  .input {
    display: flex;
    align-items: center;
    background-color: #333;
    border-radius: 8px;
    padding: 12px;
  }
  
  .inputText {
    color: #fff;
  }
  
  .textInput {
    background-color: #333;
    color: #fff;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    border: none;
    width: 100%;
  }
  
  .checkboxContainer {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    cursor: pointer;
  }
  
  .checkboxLabel {
    color: #fff;
    margin-left: 8px;
  }
  
  .button {
    background-color: #800080;
    border-radius: 8px;
    padding: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    border: none;
    cursor: pointer;
  }
  
  .button:disabled {
    background-color: #555;
  }
  
  .buttonText {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
  