.new-container-div2{
    box-sizing: border-box;
    padding: 2% 10%;
    box-sizing: border-box;
    margin-top: 10.5rem;
   margin-bottom: 3.2rem;
  }
  .about-gaming{
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
}
.text-gaming{
   width: 30rem;
    .vertical-text span {
        display: block;
        text-align: left;
        font-size: 66px;
        line-height: 1.5;
        font-family: var(--Poppins);
        text-wrap: wrap;
      }
}
.pic-gaming{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.pic1{
    width: 13.125rem;
    height: 23.625rem;
    border-radius: 10px;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.pic2{
    width: 38.125rem;
height: 23.625rem;
border-radius: 10px;
img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
}
.highlight{
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(to right,#391C72,#CD00AA,white);
}
.size{
    font-family: var(--Poppins);
    font-size: 66px;
    text-wrap: wrap;
}
.about-gaming2{
    width: 100%;
display: flex;
flex-wrap: wrap;
flex-direction: column;
align-items: center;
justify-content: center;
gap:1rem;
margin-top: 5rem;
    .para-gaming{
        width: 100%;
        text-wrap: wrap;
        text-align: center;
        font-family: var(--lato);
        padding: 0 20%;
    }
    h3{
         font-family: var(--Poppins);
    }
    h1{
        font-size: 66px;
    }
}
.team-show-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 3.5rem;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}
.team-container{
    width: 11.9375rem;
    height: 17.5625rem;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   box-sizing: border-box;
   background: rgba(255, 255, 255, 0.23);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
   overflow: hidden !important;
   gap: 1rem;
   position: relative;
   border-radius: 10px;
   border: 1px solid #ffffff57;
   h3{
    z-index: 10;
   }
   p{
    z-index: 10;
   }
}
.circle-div{
    width: 9.25rem;
    height: 9.25rem;
    background-color: wheat;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
    }
}
.down-arow{
    position: absolute;
    z-index:9 ;
    color: rgb(207, 52, 197);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-size: 32rem;
    margin-top: -6rem;
    overflow: hidden !important;
}
.about-gaming3{
    margin-top: 5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.gaming3-div1{
    width: 31.375rem;
    h1{
        font-family: var(--Poppins);
        font-size: 66px;
        text-wrap: wrap;
    }
}
.two-div-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
}
.two-div1{
    width: 14.75rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p{
        text-wrap: wrap;
    }
}
.two-img{
    width: 40px;
    height: 40px;
    img{
        width: 100%;
        height: 100%;
    }
}
.heading-two{
    font-family: var(--Poppins);
    font-size: 1.2rem;
    text-wrap: wrap;
}
.gaming3-div2{
    width: 38.125rem;
height: 49.0625rem;
border-radius: 10px;
img{
    width: 100%;
    height: 100%;
}
}
@media screen and (min-width: 768px){
    .about-gaming{
        justify-content: center;
    }
    .about-gaming3{
        gap:2rem;
        justify-content: center;
    }
    .about-gaming{
        gap: 2rem;
    }
    .about-gaming2{
        width: 100%;
        .para-gaming{
            text-wrap: wrap;
            text-align: center;
            font-family: var(--lato);
        }
        h3{
             font-family: var(--Poppins);
        }
    }
}
@media screen and (max-width: 768px){
    .about-gaming3{
        gap:2rem;
        justify-content: center;
    }
    .about-gaming{
        gap: 2rem;
    }
    .text-gaming{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .vertical-text span{
        text-align: center;
    }
    .pic-gaming{
        width: 100%;
    }
    .pic2{
   height: auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .about-gaming2{
        width: 100%;
        justify-content: center;
        .para-gaming{
            width: 100%;
            text-wrap: wrap;
            text-align: center;
            font-family: var(--lato);
        }
        h3{
             font-family: var(--Poppins);
        }
    }
    .about-gaming3{
        justify-content: center;
        align-items: center;
    }
}