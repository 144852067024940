
.main-con{
    width: 80%;
    margin-top: -4rem;
    display: flex;
    
}
.line-img{
    display: block;
}
@media screen and (max-width: 900px){
    .size-top{
        width: 100%;
       
     }
}
@media screen and (max-width: 768px){
    .main-con{
        width: 100%;
    }
    .faq-container{
        width: 100%;
    }
    .faq-item {
        width: 100%;
       
    }
    .line-img{
        display: none;
    }
     .size-top{
        width: 80vw;
       
     }
}
