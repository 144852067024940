@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-size: cover;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.new-super-div{
  width: 100%;
  /* background:linear-gradient(to right, #20041c, #391c72); */
  margin-bottom: -3.2rem;
}
.new-container-div{
  margin-top: 10rem;
  padding-bottom: 4rem;
  box-sizing: border-box;
  padding: 0 10%;
  box-sizing: border-box;
  margin-top: 14rem;
 margin-bottom: 3.2rem;
 min-height: 85vh;
}

.faq-container {
  width: 100%;
  margin: 50px auto;
  padding: 20px;
  padding-bottom: 4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  gap: 1rem;
 
   h2{
    font-family: var(--lato);
   }
   h1{
    font-family: var(--Poppins);
   }
}

.faq-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
}

.faq-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
  padding: 15px 15px;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: #fff; 
  border: 1px solid #cccccc6c;
  padding-left: 1rem;
  box-sizing: border-box;
  padding-bottom: 1rem;
  box-sizing: border-box;


}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.2em;
}

.faq-question h2 {
  flex: 1;
  margin: 0 10px;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 15px;
  box-sizing: border-box;
}

.faq-answer.show {
  max-height: 500px;
  transition: max-height 0.5s ease;
}

.faq-answer p {
  margin: 10px 0 0;
}

.faq-item.active .faq-question span {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.faq-question svg {
  margin-right: 10px;
  color: white;
}

.faq-question span {
  transition: transform 0.3s;
}
.icon-arrow{
  color: red
}
.vertical-line {
 margin: 1.5rem 0;
 border-color: #64196a;
}
.faq-heading{
  margin: 5rem 0;
  padding: 0 10%;
  box-sizing: border-box;
  h1{
    font-size: 3.5rem;
  }
}
.marquee-img{
 position: absolute;
 z-index: 200;
 right: 9%;
 top: 14%;
 scale: 0.9;
 display: block;
}

@media screen and (max-width: 768px){
  .faq-container{
    width: 100%;
  }
  .faq-item{
    width: 100%;
  }
 
  .new-container-div{
     padding: 0 1rem;
    box-sizing: border-box;
    overflow-x: hidden;
  }
 
}
@media screen and (max-width: 768px){
  .marquee-img{
    display: none;
  }
}