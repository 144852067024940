


.wiper-track {
    display: flex;
    transition: transform 0.4s ease-in;
    padding: 0 1rem;
}
.wiper-item {
    width: 200px;
    height: 300px;
    position: relative;
    transition: transform 0.2s linear;
    margin: 0 12px; /* 24px total between items */
}
.wiper-button img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.wiper {
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}
.active-swipe {
    transform: scale(1.1);
}
.wiper-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    padding: 2rem 0.5rem;
    margin: 0 auto;
    position: relative;
}
.carousel-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 220px; /* Adjust based on your frame's size */
    height: 320px; /* Adjust based on your frame's size */
    transform: translate(-50%, -50%);
    pointer-events: none; /* Ensure frame does not block clicks */
}
.frame-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper__image-button {
    right: 50%;
    top: 50%;
    position: absolute;
    padding: 0.8rem 1rem;
    border-radius: 3px;
    background-color: white;
    border: none;
    font-size: 1rem;
    transform: translateY(-50%) translateX(50%);
    cursor: pointer;
}
.swiper__image-button:hover {
    color: red;
}
.wiper__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
}
.is-hidden {
    display: none;
}
