/* RoomScreen.css */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.containerroom {
  width: 100vw;
margin-top: 6rem;
  padding: 91px 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2vw;
 min-height: 100vh;
}

.buttonContainer {
  display: flex;
  min-width:10rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.gradient {
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
  cursor: pointer;
}
.create-room-btn {
  background: linear-gradient(90deg, #a903d2, #410095);
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
width:20rem !important;
height: 52px;
font-family: var(--poppins);
display: flex;
justify-content: center;
align-items: center;
width: 100%;
border: none;
cursor: pointer;
color: #fff;
font-size: 16px;
margin-bottom: 20px;
border-radius: 10px;
}
.join-room-btn {
  background-image:linear-gradient(45deg, #a903d2b1, #E17643) ;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
width:20rem !important;
height: 52px;
font-family: var(--poppins);
display: flex;
justify-content: center;
align-items: center;
width: 100%;
border: none;
cursor: pointer;
color: #fff;
font-size: 16px;
margin-bottom: 20px;
border-radius: 10px;
}
.recentRoomsHeader {
  color: #fff;
  font-weight: bold;
  margin: 10px 0;
  font-size: 18px;
  text-align: center;
}

.recenthead {
  color: var(--System-White---Black, #FFF);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  margin-bottom: 16px;
}

.recentRooms {
  width: 65%;
}

.recentRoomsList {
  display: flex;
  scroll-behavior: smooth;
  grid-template-columns: auto auto auto auto;
  flex-direction: column;
  padding-bottom: 100px;

}

::-webkit-scrollbar {
  display: none;
}

.roomCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.11);
  padding: 10px;
  margin-bottom: 16px;
  width: 100%;
}

.roomName {
  color: var(--Dark-Gray-11, #FEFEFE);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
}

.roomDetails {
  color: var(--Dark-Gray-11, #FEFEFE);
  height: 18px;
  align-self: stretch;
  /* Body/Small */
  margin-top: 4px;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
}
.member_id{
  display: flex;
padding: 6px 12px;
align-items: center;
gap: 4px;border-radius: 8px;
background: rgba(0, 0, 0, 0.44);
margin-top:4px ;
}

.mem_cnt{
  color: var(--Dark-Gray-11, #FEFEFE);

/* Body/Small */
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
letter-spacing: 0.4px;
}
.mem_count{
  color: var(--Dark-Gray-11, #FEFEFE);
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 114.286% */
letter-spacing: 0.4px;
}



  .joinButton {
display: flex;
width: 69px;
height: 74px;
padding: 13px 26px;
justify-content: center;
align-items: center;
gap: 10px;
color: white;
border-radius: 10px;
background: linear-gradient(225deg, #A903D2 0%, #410095 100%);
border: none;
  }


.joinButton:hover {
  background-color: #7b1fa2;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
.background-imagee{
  background-color: transparent;
}
.admin-rooms-display{
  height: 20rem;
  overflow: scroll;
}

@media only screen and (max-width: 768px) {

  .containerroom{
    width: auto;
    flex-direction: column;
    padding: 0;
    margin-top: 8rem;
 
  }
  .buttonContainer{
    width: auto;
  }
  .line-size{
    display: none;
  }
  .recentRooms{
    margin:0 20px;
    width: 90%;
  }
}