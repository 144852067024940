*{
    margin: 0;
    padding: 0;
}
.main-footer{
    width: 100%;
    height: auto;
    /* background:
      linear-gradient(to right, #080107, #391C72); */
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    backdrop-filter: blur(25px);
    padding-top: 5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    margin-bottom: 1rem;
    overflow: hidden;
}
.main-footer::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: url(./css_asset/blurimage.png);
    z-index: -1;
    overflow: hidden;
}
.logo-container{
    width: 100%;
    text-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 10%;
    box-sizing: border-box;
    gap: 50px;
    overflow: hidden;
    p{
        font-size: 16px;
        font-family: var(--lato);
        line-height: 29px;
    }
}
.foo{
    display: flex;
     flex-direction: column;
     gap:5;
     overflow: hidden;
}
.footer-div1{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    overflow: hidden;
}
.detail1{
    display: flex;
    flex-direction: column;
    gap: 10px;
    a{
        font-size: 17px;
    }
}
.links-div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer-div2{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    overflow: hidden;
    img{
        width: 165px;
        height: 29px;
    }
}
.circle{
    width:28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #ffffff57;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon{
  font-size: 15px;
}
.footer-div3{
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 10% ;
    background-color: rgba(0, 0, 0, 0.616);
    overflow: hidden;
}
.circle2{
    background-image: url('./css_asset/btnbg.png');
    background-size: 320%;
    background-position: center;
    background-repeat: no-repeat;
    width:28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #ffffff57;
    display: flex;
    justify-content: center;
    align-items: center;
}
.copy{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   text-align: center;
   text-wrap: wrap;
   overflow: hidden;
}
.footer-div-2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    p{
        text-wrap: wrap;
    }
}
.social-handles{
    display: flex;
    gap: 10px;
}
.marquee-container2{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    color: white;
    background: rgba(255, 255, 255, 0.23);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transform: rotate(2deg) translateY(-20px); /* Adjust translateY value as needed */
    position: absolute;
    height: 80px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-bottom: 10rem; /* Adjust margin-bottom as needed */
    z-index: 100;
    margin-top: 2rem;
    overflow: hidden;
    h2{
        font-family: var(--poppins);
        font-size: 30px;
    }
}
@media only screen and (min-width: 768px){
    .main-footer{
        border: none;
    }
    .logo-container{
        margin-top: 2.5rem;
    }
}
@media only screen and (max-width: 768px){
    .detail1{
        margin-top: 8%;
    }
    .main-footer{
        border:none;
        /* background-size: cover; */
       border: none !important;
    }
    .marquee-container2{
        margin-bottom: 10rem;
    }
    .logo-container{
        margin-top: 5rem;
    }
}
@media only screen and (max-width: 420px){
    .logo-container{
        margin-top: 5rem;
    }
}