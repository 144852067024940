*{
    margin:0;
    padding: 0;
}
.super-main-sider{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
}
.main-sider-div{
    width: 100%;
    /* overflow-x: hidden; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap:5%;
    transition: transform 0.5s ease-in-out;
    
}
.item-div{
    background: rgba(12, 12, 12, 0.23);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
   
    
  
}
.item-div::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: url(./css_asset/blurimage.png);
    z-index: -1; 
}
.item-div{
    width: 45%;
    padding: 20px;
    padding-top: 40px;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    text-wrap: wrap;
    gap: 10px;
    border: 1px solid  #ffffff57;
    margin-top: 10px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    /* overflow: hidden; */
    position: relative;
}

.item-div::after {
    content: '';
    position: absolute;
    top: -15%;
    left: 75%; 
    width: 6.26575rem;
height: 4.90969rem;
    background-image: url(./css_asset/Groupsvg.svg); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    border-radius: 20px; 
    z-index: 1; 
    border-radius: 0;
}



.item-div.active {
    transform: scale(1.05); 
    opacity: 1; 
  }
.review-img{
    width: 120px;
    height: 25px;
    img{
        width: 100%;
        height: 100%;
    }
}
.contentt{
  font-family: var(--lato);
  font-size: 16px;
  line-height: 29px;

}
.lineimg{
    width:100%;
    img{
        width: 100%;
    }
}
.people-detail{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wraper{
    display: flex;
    gap: 10px;
    align-items: center;
}
.people-img{
    width:61px;
    height: 61px;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
    }
}
.people-content{
    display: flex;
    flex-direction: column;
    .people-name{
        font-size: 20px;
        font-family: var(--lato);
    }
    .people-from{
        font-size: 11px;
        font-family: var(--lato);
    }
}
.verify-img{
    display: flex;
    gap:10px;
    img{
        width: 24px;
        height: 24px;
    }
}
.carousel-container {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px; /* Add some space between dots */
    
  }
  
  .carousel-dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    background-size: cover; /* Ensure the image is contained within the dot */
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .carousel-dot.active {
    background-image: url('./css_asset/btnbg.png');
  }
  
  .carousel-dot.inactive {
    background-image: url('https://via.placeholder.com/15/bbbbbb');
  }
  @media screen and (max-width: 768px){
    .item-div{
        width: 40%;
        margin-top: 5rem;
       
    }
    .item-div::after{
        left:60%;
       top: -12%;
    }
    .content{
        width: 40%;
        position: relative;
    }
  }

  @media screen and (max-width: 768px){
    .item-div{
        width: 600px;
       
        
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    .item-div::after{
        left: 65%;
        top: -12%;
    }
  }
  
  