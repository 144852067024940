.mainView {
    display: flex;
    flex-direction: column;
    background-color: #000;
    min-height: 100vh;
    padding-bottom: 100px;
    justify-content: space-between;
    position: relative;
  }
  
  .backgroundStyle {
  
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 80%;
    z-index: -1;
  }
  
  .safeArea {
    padding: 16px;
  }
  
  .headerContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    flex: 1;
  }
  
  .lineImage {
    margin-top: 0;
    align-self: center;
  }
  
  .balanceContainer {
    margin: 10px 0;
    text-align: center;
  }
  
  .balanceText {
    color: #fff;
    font-size: 20px;
  }
  
  .inputContainer {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding-left: 16px;
    margin-bottom: 10px;
    padding: 0.5rem;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  .input {
    flex: 1;
    height: 60px;
    color: #fff;
    margin-left: 16px;
    background: transparent;
    border: none;
    outline: none;
  }
  
  .quickText {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .quickFillRow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .quickFillButton {
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 16px;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 10px;
  }
  
  .quickFillButton:hover {
    background-color: rgba(255, 255, 255, 0.8);
    transform: scale(1.05);
  }
  
  .loginButton {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(90deg, #a903d2, #410095);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .loginButton:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .linkcont {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  
  .linkcont button {
    background: none;
    border: none;
    color: #a903d2;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .linkcont button:hover {
    text-decoration: underline;
  }
  