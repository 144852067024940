.main-setup{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:linear-gradient(to right, #20041c, #391c72);
  box-sizing: border-box;
  overflow: hidden;
}
.containerprofile  {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 40%;
  margin-top: 4.5rem;
}

.title {
  font-size: 2.25rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  font-family: var(--poppins);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    margin: 5px;
  }
}
.semi-heading{
  color: #ccccccc2;
  font-size: 1.5rem;
  font-family: var(--lato);
}
.semi-heading2{
  color: #ccccccc2;
  font-size: 1.25rem;
  font-family: var(--lato);
}
.label {
  font-size: 16px;
  color: #ddd;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  margin-top: 1rem;
}

.inputContainer1 {

  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: yellow;
  gap: 1rem;
}

.input1 {
  /* background-color: #fff2; */
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  height: 40px;
  width: 100%;
  border: none ;
background-color: transparent;
}
.input1:focus {
  border: none;
  border-color: #ccc;
  box-shadow: none;
}

.input_disabled {
  background-color: #fff2;
  color: #fff5;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  height: 40px;
  width: 100%;
}

.errorText {
  color: red;
  margin-top: 5px;
}

.avatarContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 60%;
  gap: 16px;
  margin-bottom: 20px;

}

.grayscale {
  filter: grayscale(100%);
}
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 25px;
  border-width: 2px;
  border-color: transparent;
  cursor: pointer;
}

.selectedAvatar {
  filter: none;
  border-color: #a903d2;
  border-width: 4px;
}

.saveButton {
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
  background-color: #a903d2;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.disabledButton {
  opacity: 0.5;
}
.setup-div{
  display: flex;
 flex-direction: column;
 align-items: center;
 /* padding: 2rem; */
 box-sizing: border-box;
 gap: 0.5rem;
 padding: 0;
}
.semi{
  text-align: center;
}
.div-countainer{
  width: 20rem;
 
}
.blur{
  color: rgba(255, 255, 255, 0.42);
  font-size: 0.9rem;
  margin-bottom: 5px;
}
.long{
  margin-top: 2rem;
  width: 10rem;
}
.agree{
  color: #cccccca8;
  font-size: 0.9rem;
}




/* Responsive Styles */

@media only screen and (max-width: 768px) {
  .main-setup{
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
   
  }
  .containerprofile{
    width: 100%;
    margin-top: 8rem;
  }
  .input,
  .input_disabled {
    font-size: 14px;
    height: 36px;
  }

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 20px;
  }

  .selectedAvatar {
    border-width: 3px;
  }

  .saveButton {
    height: 36px;
    font-size: 14px;
  }
  .main-setup{
    width: 100% !important;
  }
  .div-countainer{
    display: flex;flex-direction: column;
    align-items: center;
    justify-content: center;
   
  }
}
@media only screen and (max-width: 420px) {
  .container{
  margin: 4rem 0;
  }
}