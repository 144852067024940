.send-otp-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color:transparent;
  width: 100% !important;
}
.background-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.back-button {
  /* position: absolute; */
  top: 50px;
  left: 20px;
  background: none;
  border: none;
}
.icon {
  width: 24px;
  height: 24px;
}
.content {
  text-align: center;
  color: #fff;
}
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.subtitle {
  font-size: 16px;
  margin-bottom: 30px;
}
.input-container {
  width:100%;
  height: 3rem;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding-left: 16px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.input {
  flex: 1;
  height: 3rem !important;
  color: #fff;
  margin-left: 16px;
  background: none !important;
  border: none;
  outline: none;
  display: flex;
}
.login-button {
  background-image: url('./css_asset/btnbg.png');
  width: 230px;
  height: 40px;
  font-family: var(--poppins);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.loader {
  border: 4px solid #F3F3F3;
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
.abs-ack{
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 6.25rem;
  box-sizing: border-box;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.bg-clr{
  background-color: transparent;
}
@media screen and (max-width: 768px) {
  .background-image{
    width: 100%;
   padding: 0 2rem ;
    box-sizing: border-box;
  }
  .content{
   width: 100%;
 
  }
  .background-image {
    box-sizing: border-box;
  }
  .abs-ack{
  
    padding:0.5rem 2rem;
  }
  .abs-ac{
    padding:0 2rem;
  }
} 