.settings-container {
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 8px;
    color: #fff;
    max-width: 600px;
    margin: 0 auto;
}

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
}

.user-details {
    flex-grow: 1;
}

.uid {
    color: #a1a1a1;
}

.edit-profile-button {
    background: none;
    border: none;
    cursor: pointer;
}

.settings-options {
    background-color: #2c2c2c;
    padding: 15px;
    border-radius: 8px;
}

.option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.option label {
    font-size: 16px;
}

.option input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.option select {
    padding: 5px;
    background-color: #3c3c3c;
    color: #fff;
    border: none;
    border-radius: 4px;
}

.flex-just {
    justify-content: space-between;
    padding: 0.625rem 2rem;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
}

.card-width {
    width: 40rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;
   
}

.flex {
    display: flex;
    flex-direction: row;
    gap: -1rem;
}

.input-card-contain {
    display: flex;
    padding: 0.625rem 2rem;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.input-card {
    display: flex;
    height: 4.125rem;
    padding: 0rem 1.25rem;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    align-self: stretch;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.10);

    p {
        color: var(--Dark-Gray-11, #FEFEFE);
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
    }
    input[type="checkbox"] {
        -webkit-appearance: none; 
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #ccc; 
        border-radius: 50%; 
        width: 25px; 
        height: 25px; 
        margin-right: 8px; 
        cursor: pointer;
      }
      input[type="checkbox"]:checked {
        background:linear-gradient(#E158E1,#7266F9)
      
        
      }
      
    
      input[type="checkbox"]:checked::after {
        content: ""; /* Required for pseudo-element */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px; /* Size of the icon */
        height: 16px;
        background-image: url(''); /* Replace with your icon image */
        background-size: cover; /* Adjusts image size */
      }

    select {
        background-color: transparent;
        border: none;
        outline: none;
        color: var(--Grey-400, #BDBDBD);
        text-align: right;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

}

@media screen and (max-width:768px) {
    .card-width {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .flex{
        scale: 0.8;
    }
    .flex-just{
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .main-card-div{
     margin: 0;
        padding: 0;
    }
}