.container {
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding: 16px;
}

.header {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.iinputContainer {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.label {
  color: #fff;
  margin-bottom: 8px;
  font-size: 16px;
}

.lvl-pop {

  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.42);
  font-family: Roboto;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.stl-spn {
  color: var(--Greyscale-500, #9E9E9E);
  /* font-family: "SF Pro Rounded"; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.0125rem;
}

.inputWrapper {
  display: flex;
  align-items: center;
  background-color: #333;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 16px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.input {
  flex: 1;
  color: #fff;
  font-size: 16px;
  background: none;
  border: none;
  outline: none;
}

.disabledInput {
  cursor: not-allowed;
}

.termsContainer {
  display: flex;
  align-items: center;

}

.term-input {
  margin-top: 2px;
}

.new-size {
  border-radius: 1rem;
  display: flex;
  height: 3.5rem;
  padding: 0rem 1.25rem;
  box-sizing: border-box;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  margin-top: 2px;
}

.termsText {
  color: #fff;
  margin-left: 10px;
  font-size: 16px;
}

.createRoomButton,
.joinRoomButton {
  margin: 10px 0;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.gradient {
  background: linear-gradient(90deg, #FF9800, #F44336);
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.joinRoomButtonText {
  background: linear-gradient(90deg, #7B1FA2, #8E24AA);
}

.orText {
  color: #fff;
  text-align: center;
  margin: 10px 0;
  font-size: 16px;
}

.recentRoomsHeader {
  color: #fff;
  font-weight: bold;
  margin: 10px 0;
  font-size: 18px;
}

.recentRoomsList {
  padding-bottom: 100px;
}

.roomCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.roomName {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.roomDetails {
  color: #ccc;
}

.joinButton {
  background-color: #8E24AA;
  border-radius: 10px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}