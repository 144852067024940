.slider-img{
    width:200px;
    height: 200px;
}
.carousel{
    width: 100%;
    display: flex;
    gap:10%;
    justify-content: center;
    flex-wrap: wrap;
}
.img{
    width:25rem;
    height: 15rem;
}