.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.911);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  background-color: grey;
  padding: 2rem;
  box-sizing: border-box;
}

.overlay-content {
  background-color: #000000d0;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 3rem;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.596) !important;

  button {
    border: none;
  }
}

.overlay-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: url(../../../images/blurimage.png);
  z-index: -1;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.head-addmoney {
  display: flex;
  justify-content: space-between;

  h2 {
    color: var(--Dark-Gray-11, #FEFEFE);
    font-family: Roboto;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

.height {

  height: 56px;
}

.newone {

  margin-bottom: -1px;
}

.inputbox {
  display: flex;
  align-items: center;
  background-color: #333;
  border-radius: 8px;
  padding: 20px;

}

.inutcontain {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding-left: 16px;

}

.new-btn {
  background-color: #fff;
  padding: 1rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  border-radius: 10px;
  font-weight: bold;
  background: linear-gradient(90deg, #a903d2, #410095);
  color: white;
  border: none;
}

.picker {
  background-color: transparent;
  width: 80%;
  border: none;
  font-size: 0.9rem;
  margin-left: 1.6rem;
  color: var(--Greyscale-500, #9E9E9E);
font-family: var(--lato);
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 1.225rem */
letter-spacing: 0.0125rem;

}
.picker:after{
  content: 'hy';
  font-size: 30px;
  line-height: 23px;
  padding-right: 2px;
  background-color: red;
}
.picker:focus{
  border: none;
  outline: none;
}

.picker2 {
  background-color: #00000091;
}

@media screen and (max-width:768px) {
  .overlay-content {
    padding: 10px 20px !important;
  }

  .overlay {
    padding: 2rem 10px;
    box-sizing: border-box;
    background-color: red;
  }
}