* {
    margin: 0;
    padding: 0;
}

.super-main {
    overflow: hidden;
}

.home-main {
    /* Combine Linear Gradient and Background Image */
    background:
        linear-gradient(to right, #20041c46, #391c721a),
        url('../images/bg-rummy.png') left / contain no-repeat,
        /* Position the image to the left */
        #20041c;
    /* Solid color fallback */
    /* mix-blend-mode: color-dodge; */
    background-blend-mode: exclusion;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right 3.5rem;
    padding: 0 10%;
    box-sizing: border-box;
}

.head {
    width: 100%;
}

.home-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

}

.texts {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    width: 600px;
    text-wrap: wrap;
    margin-bottom: 8%;

    h1 {
        font-size: 68px;
        font-family: var(--poppins);
        text-transform: uppercase;
        text-wrap: wrap;
        color: #FFFFFF;

    }

    p {
        font-family: var(--lato);
        color: #FFFFFF;
    }

    .color {
        color: transparent;
        /* Make the text color transparent */
        background-clip: text;
        -webkit-background-clip: text;
        /* For Safari */
        background-image: linear-gradient(to right, #391c72, #cd00aa, white);
    }


}

.button-divv {
    display: flex;
    gap: 2rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
}

.big-image {
    width: 40rem;
    height: 40rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.btn1 {
    display: flex;
    width: 14.5rem;
    height: 4.625rem;
    padding: 0.8125rem 1.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    background: var(--Linear, linear-gradient(225deg, #A903D2 0%, #410095 100%));
    border: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110.6%;
    letter-spacing: 0.05rem;
    text-transform: uppercase;

}

.btn2 {
    display: flex;
    padding: 0.8125rem 1.625rem;
    box-sizing: border-box;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
 /* Ensure border-radius is applied */
    font-family:var(--poppins);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110.6%;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid transparent; 
    border-radius: 0.625rem !important;/* Ensure border style is set */
    /* Gradient Border */
    box-shadow: 0 0 0 1.5px #4A019B, 0 0 0 1.5px #9D03CB;
   
}

.btn2-gre {
    color: transparent;
    /* Make the text color transparent */
    background-clip: text;
    -webkit-background-clip: text;
    /* For Safari */
    background-image: linear-gradient(to right, #391c72, #cd00aa, white);
}



.count1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: red;
}

.happy-customer {
    width: 100%;
    display: flex;
    gap: 30px;

    h2 {
        color: #FFFFFF;
        font-size: 30px;
    }
}

.counter1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    P {
        text-align: center;
    }
}

.marquee {
    color: white;
    background-color: rgba(255, 255, 255, 0.192);
    transform: rotate(-2deg);
    position: absolute;
    height: 80px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-top: 1px solid white;
    border-bottom: 1px solid white;

    h2 {
        font-family: var(--poppins);
        font-size: 30px;
    }

    .star {
        display: flex;
        align-items: center;
        gap: 5px;
        animation: marquee 20s linear infinite;
    }

    @keyframes marquee {
        from {
            transform: translateX(0%);
        }

        to {
            transform: translateX(-100%);
        }
    }

    img {
        margin-left: 50px;
    }

}



/* Marquee.css */

/* Marquee.css */

.marquee-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    color: white;
    background: rgba(255, 255, 255, 0.23);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transform: rotate(-2deg) translateY(-20px);
    /* Adjust translateY value as needed */
    position: absolute;
    height: 80px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-bottom: 10rem;
    /* Adjust margin-bottom as needed */
    z-index: 10;

    h2 {
        font-family: var(--poppins);
        font-size: 30px;
    }
}


.marquee-content {
    display: flex;


}

.marquee-item {
    display: flex;
    margin-right: 50px;
    /* Space between items */
    gap: 10px;
}

@keyframes marquee {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}



/* main div2 */
.main2 {
    background: linear-gradient(to right, #20041c, #000);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    padding: 0px 10%;
    box-sizing: border-box;
    overflow: hidden;

}

.main2-heanding {
    width: 700px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 5rem 0;

    h1 {

        font-size: 68px;
        font-family: var(--poppins);
        text-transform: uppercase;
        text-wrap: wrap;
        color: #FFFFFF;
    }

    p {
        font-family: var(--lato);
    }

    .colorr {
        color: transparent;
        /* Make the text color transparent */
        background-clip: text;
        -webkit-background-clip: text;
        /* For Safari */
        background-image: linear-gradient(to right, #391c72, #cd00aa, white);
    }
}

.btn-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap !important;
    gap: 2rem !important;
    justify-content: center;
    align-items: center;

}

.add {
    display: flex;
    padding: 0.8125rem 1.625rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.625rem;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110.6%;
    /* 1.3825rem */
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid transparent;
    /* Gradient Border */
    box-shadow: 0 0 0 1.5px #4A019B, 0 0 0 1.5
    1.5px #9D03CB;
   
}
.add2{
    display: flex;
    padding: 0.8125rem 1.625rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.625rem !important ;
    font-family:var(--poppins);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110.6%;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid transparent;
    /* Gradient Border */
    box-shadow: 0 0 0 1.5px #4A019B, 0 0 0 1.5px #9D03CB;
   
}
.new-btn2 {
    padding-top:1.5rem;
    padding-bottom: 1.5rem;
    box-sizing: border-box;
    color: white;
    border-radius: 0.625rem !important;
}

.sliderhome {
    width: 100%;
    background-color: red;
    display: flex;
    gap: 5%;
    justify-content: center;
    overflow: hidden;
}

.image-contain {
    width: 25rem;
    height: 15rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.supermain3 {
    width: 100%;
    padding: 0 6%;
    box-sizing: border-box;

}

.main3 {
    padding: 0px 5%;
    padding-top: 5%;
    box-sizing: border-box;
}

.main3-div {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(12, 12, 12, 0.23);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(25px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;

    .main3-img {
        width: 35rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }


}

.main3-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: url(../images/blurimage.png);
    z-index: -1;
}

.main3-content {
    width: 550px;
    text-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 30px;

    h2 {
        font-family: var(--poppins);
        font-size: 48px;
        text-transform: uppercase;
    }

    p {
        font-family: var(--lato);
    }

    .color {
        color: transparent;
        /* Make the text color transparent */
        background-clip: text;
        -webkit-background-clip: text;
        /* For Safari */
        background-image: linear-gradient(to right, #391c72, #cd00aa, white);
    }


}

.marquee2 {
    color: white;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    bottom: 10%;
    margin-top: 5rem;

    h2 {
        font-family: var(--poppins);
        font-size: 30px;
    }

    .star {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 5rem;

    }

    img {
        margin-left: 50px;
    }
}

.supermain4 {
    width: 100%;
    /* background: 
      linear-gradient(to right, #20041c, #391c72); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main4 {
    width: 100%;
    padding: 0px 10%;
    box-sizing: border-box !important;
    padding-top: 5%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.main4-heading {
    width: 700px;
    text-wrap: wrap;
    padding: 0 5%;
    text-align: center;

    h1 {
        font-size: 68px;
        font-family: var(--poppins);
        text-transform: capitalize;
        text-wrap: wrap;
        color: #FFFFFF;
        text-transform: uppercase;
    }

}

.print-data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.main-array {
    width: 395px;
    display: flex;
    flex-direction: column;
    border: 10px;
    background: rgba(12, 12, 12, 0.23);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    gap: 10px;
    border: 1px solid #ffffff57;
}

.main-array::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: url(../images/blurimage.png);
    z-index: -1;
}

.array-img {
    width: 100%;
    height: 260px;
    border-radius: 10px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
}

.array-heading {
    font-family: var(--lato);
    font-size: 24px;
}

.player-div {
    display: flex;
    align-items: center;
    gap: 2%;

    img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
    }

    .player-detail {
        display: flex;
        flex-direction: column;

        .player-name {
            font-size: 18px;
            font-family: var(--lato);
        }

        .game-name {
            font-family: var(--lato);
            font-size: 10px;
        }
    }
}

.livedemo {
    background-image: url('../images/btnbg.png');
    background-size: cover;
    width: 100%;
    height: 4.625rem;
    font-family: var(--poppins);
    display: flex;
    padding: 0.8125rem 1.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border: none;
    border-radius: 0.625rem;
    background: var(--Linear, linear-gradient(225deg, #A903D2 0%, #410095 100%));
    color: #FFF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110.6%;
    /* 1.3825rem */
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}

.main5 {
    /* background: 
      linear-gradient(to right, #20041c, #391c72); */
    padding: 0px 10%;
    padding-top: 5%;
    box-sizing: border-box;
    width: 100%;
    align-items: center;

}

.btn-div {
    display: flex;
    gap: 10px;

}

.add {
    /* Default background color */
    border: none;
    padding: 10px;
    cursor: pointer;
}

.activeie {
    padding: 0.8125rem 1.625rem;
    box-sizing: border-box;
    border-radius: 0.625rem;
    background: var(--Linear, linear-gradient(225deg, #A903D2 0%, #410095 100%));
    color: white !important;
    font-family: var(--lato);
    background-size: cover;
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 110.6%;
    letter-spacing: 0.045rem;
    text-transform: uppercase;
    border: none;
    box-shadow: none;
}
.active .btn2-gr {
   color: white;
}
.btn2-gr{
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    /* For Safari */
    background-image: linear-gradient(to right, #391c72, #cd00aa, white);
}
.adj-width{
    display: inline-flex;
height: 4.625rem;
padding: 0.8125rem 1.625rem;
align-items: center;
gap: 0.625rem;
flex-shrink: 0;
width: 40%;
display: flex;
align-items: center;
justify-content: center;
}

@media screen and (min-width: 768px) {
    .texts {

        margin-top: 10rem;

    }
}

@media screen and (min-width: 867px) and (max-width: 1550px) {
    .print-data {
        width: 130%;
        padding: 0 10%;
        box-sizing: border-box;
        gap: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .home-main {
        background:
            linear-gradient(to right, #20041c, #391c72),
            url('../images/bg-rummy.png') center / cover no-repeat,
            /* Center the image and cover the background */
            #20041c;
        /* Solid color fallback */
        background-size: cover;
        /* Make the background image cover the entire background */
        background-position: center;
        /* Center the background image */
    }

    .super-main {
        overflow: hidden;
        margin-top: -8%;
    }

    .texts {
        h1 {
            font-size: 30px;
        }

        p {
            font-size: 14px;
        }
    }

    .btn1 {
        width: 146px;
        background-position: center;
        font-size: 1rem;
    }

    .btn2 {
        width: 146px;
    }

    .add {
        width: 9.8rem;
        background-position: center;
    }

    .happy-customer {
        margin-bottom: 5%;
    }

    .main2 {
        padding: 0 10%;
        box-sizing: border-box;
    }

    .main2-heanding {
        text-align: center;
        width: 100%;

        h1 {
            font-size: 30px;
            width: 100%;
        }
    }

    .main3-div::before {
        display: none;
    }

    .main3-div {
        width: 100%;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 40rem;
            height: 5rem;
        }
    }

    .main3-div {
        padding: 2rem;
        box-sizing: border-box;
        gap: 24px;

        .main3-img {

            width: 18rem;
            height: 20rem;
        }
    }

    .main3-content {
        h2 {
            font-size: 30px;
        }
    }

    .main4 {
        padding: 0 5%;
        box-sizing: border-box;
    }

    .main4-heading {
        width: 100%;
        text-align: center;
        text-wrap: wrap;

        h1 {
            width: 100%;
            font-size: 30px;
        }
    }

    .print-data {

        box-sizing: border-box;
        background-color: transparent;
        overflow: hidden;
    }

    .btn-div {
        width: 100%;
        flex-wrap: wrap;
    }

    .marquee2 {
        margin-top: 0;

    }

    .main2-heanding {
        width: 100%;
    }

    .texts {
        width: 100%;
        margin-top: 45%;
    }

    .home-content {
        width: 100%;
        margin-top: 10%;
    }

    .button-div {
        width: 100%;
        gap: 2rem;
    }

    .main5 {
        padding: 0 5%;
        box-sizing: border-box;
    }
    .new-btn2{
        /* padding-top:0.5rem ;
        padding-bottom: 0.5rem; */
    }
    .active{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      
    }

}