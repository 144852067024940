
.account-img{
    display: flex;
    align-items: center;
    scale: 1;
}
.img-name{
    width: 110px;
    height: 110px;
    img{
        width: 100%;
        height: 100%;
    }
}
.acc-details{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    
    

}
.coin{
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    img{
        width: 40px;
        height: 40px;
        margin-top: 1rem; 
    }
}
.wr{
    display: flex;
    gap: 1rem;
}
.grey{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 2rem;
    background-color: rgba(128, 128, 128, 0.63);
    border-radius: 8px;
    padding: 0 5px;
    box-sizing: border-box;
}
.date{
    margin-left: 2rem;
    margin-top: 1rem;
}
.card{
    width: 31.29138rem;
height: 18.35419rem;
background-image: url(./css_asset/cardbg.png);
}
.share{
    width: 100%;
    display: flex;

    align-items: start;
    justify-content: space-between;
    align-items: center;
}
.name-gold{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 1.5rem;
}
.goldcoin{
    img{
        width: 50px;
        height: 50px;
    }
    p{
        font-family: var(--lato);
    }
}
.uid{
    display: flex;
    margin-left: 1.5rem;
    p{
        background-color: grey;
        height: 3rem;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #fce77b;
        color: black;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding-left:2rem;
        box-sizing: border-box;
        
       
      
      
    }
    span{
        background-color:#ffcd1d;
        height: 100%;
        display: flex;
        align-items: center;
        padding-right:2rem ;
        padding-left: 2rem;
        margin-left: 2rem;
    }
    
}
.share-img{
    margin-top: 1rem !important;
}
.yellow{
    width: 100%;
    height: 1rem;
    background-color: #ffcd1d;
    display: flex;
    align-items: center;
    img{
        position: relative;
        left: 65%;
    }
}
.vertical{
 
    /* margin-bottom: 2rem; */
  
}
.main-card-div {
    display: flex;
    gap: 2rem;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.main-card-div.show {
    opacity: 1;
    transform: translateY(0);
}

@media screen and (max-width: 500px){
    .card-details{
    padding: 0 1rem;
    box-sizing: border-box;
    }
    .account-img{
        flex-wrap: wrap;
    }
    .card{
        width: 20rem;
    }
    .account-img{
        align-items: center;
        justify-content: center;
    }
    .yellow {
        margin-top: 2rem;
        img{
           
            left: 50%;
        }
    }
    .coin{
        margin-left: 1.5rem;
    }
}