.main-admin-wrapper{
    
    padding: 0 10%;
    padding-right: 10% !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: hidden;
    justify-content: center;
}
.admin-main{
    display: flex;
   flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.id-btn-wraper{
    display: flex;
    align-items: center;
    gap:1rem;
}
.back-btn{
    display: flex;
    align-items: center;
    font-size: 34px;

}
.addd{
    background-color: black;
}
.room-id{
    height: 52px;
padding: 4px 16px;
background-color: rgba(255, 255, 255, 0.21);
border-radius: 10px;
display: flex;
align-items: center;
}
.delete-room{
    border: none;
    background-color: transparent;
    background-image: url(../../images/Rectangle\ 1.png);
    width: 235px;
    height: 52px;
    color: white;
}
.admin-line{
   
}
.all-contenet-div{

    box-sizing: border-box;
    display: flex;
    gap:0.5rem;
   
}
.mike-all-conetnt{
    width: 22.5rem;
  
    display: flex;
    flex-direction: column;
   
    gap: 1rem;
}
.mike{
    display: flex;
height: 66px;
padding: 0px 20px;
align-items: center;
justify-content: center;
width: 100%;
border-radius: 10px;
border: 1px solid #cccccc8d;
background-color: #0000005c;

}
.mike-select{
    width: 100%;
    border: none;
    background-color: transparent;
    color: white;
    
}
.mike-select:focus{
    border: none;
}
.mike-div2{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 10px;
border: 1px solid #cccccc8d;
gap: 1rem;
background-color: #0000005c;
h2{
    font-style: italic;
}
}
.mike-div3{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 10px;
border: 1px solid #cccccc8d;
gap: 1rem;
color: #cccccc68;
background-color: #0000005c;
}
.content-2{
    width: 65vw;
    display: flex;
    flex-direction: column;
 
   
}

.slick-dots {
    bottom: 20px;
    width:60vw !important;
     /* Adjust the distance from the bottom */
  }
  
  /* Styling for the individual dots */
  .slick-dots li button {
    font-size: 20px; /* Change the size of the dots */
    color: red; 
  }
  .crousl-admin-div{
    width: 90% !important;
    overflow: hidden;
   
  }
  .new-pad{

 width: 90%;
    box-sizing: border-box;
   margin-top: 5rem;
   padding:  3% 10%;
   box-sizing: border-box;
  }
  .admin-line2{
    margin: 2rem 0;
    
  }
  .admin-games-div{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  }
  .games-div{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  .games-array{
    width: 286px;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    scale: 0.9;
   
    img{
        width: 100%;
        height: 100%;
    }
  }
  .hide-div{
    display: block;
  }
  @media screen and (max-width: 888px) {
.all-contenet-div{
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hide-div{
    display: none;
}
.content-2{
    width: 100%;
    margin-left: 2rem;
}
.id-btn-wraper{
    width: 100%;
}
.small-logos-div  new-pad{
    width: 100%;
}
.crousl-admin-div{
    width: 100%;
    justify-content: center;
}
.slick-dots{
color: red;
width: 100%;
l1{
    background-color: red;
}
}
  }
