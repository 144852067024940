
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow:scroll;
    padding: 1rem 10px;
    box-sizing: border-box;
  }
  
  /* .overlay-content {

    padding: 20px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  } */
  
  .close-button {
  
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  
  }
  @media screen and (max-width:768px) {
    .overlay{
   
     /* padding-bottom: 3rem;
     padding-top: 3rem; */
     /* justify-content: start; */
     
    }
  }
  