* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.main-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.3s, box-shadow 0.3s, border-radius 0.3s;
    position: fixed;
    z-index: 1000;
    padding: 0 10%;
    padding-top: 1rem;
    box-sizing: border-box;

}

/* .main-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: url(../images/blurimage.png);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
} */
.main-header.scrolled {
    background: rgba(255, 255, 255, 0.23);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
}

.logo-div {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    width: 100px;
    height: 93px;
}

.logo-div img {
    width: 100%;
    height: 100%;
}

.links {
    display: flex;
    gap: 40px;
    transform: translateX(0);
    opacity: 1;
    transition: 1s ease;
    justify-content: center;
    align-items: center;
}

.hamburger-menu {
    font-size: 30px;
    color: #FFFFFF;
    display: none;
}

.close-menu {
    font-size: 30px;
    color: #FFFFFF;
    display: none;
}

.close-button {
    display: none;
    position: absolute;
    right: 10%;
    top: 0;
    background-color: red;
}

.avatar-home:hover {
    border-radius: 50%;
}

.avatar-home {
    position: relative;
    display: inline-block;

    img {
        width: 50px;
        height: 50px;
        border-radius: 10px;

    }
}

.burger {
    width: 5px;
    height: 5px;
    scale: 0.5;
    border-radius: 0;
}

.avtar {
    border-radius: 10px;
}

.hover-div {
    display: none;
    position: absolute;
    top: 100%;
    /* Adjust this value to position the hover div correctly */
    left: 20%;
    /* Center the div */
    transform: translateX(-50%);
    background: rgba(32, 30, 30, 0.616);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    text-align: center;
    width: 300px;
    height: fit-content;
    border-radius: 10px;

}

.avatar-home:hover .hover-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


.hover-box-1 {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;

    transform: scale(1.05);
}

.hover-box-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 20px;
    box-sizing: border-box;

    button {
        width: 100px;
        height: 50px;
        border: none;
        background-color: rgba(255, 0, 0, 0.514);
        border-radius: 10px;
        color: white;
    }

    button:hover {
        background-color: red;
    }
}

.new-signin {
    display: flex;
    width: 9.6875rem;
    height: 3rem;
    padding: 0.8125rem 1.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    background: var(--Linear, linear-gradient(225deg, #A903D2 0%, #410095 100%));
}
.a-style{
    color: #FFF;
font-family: Lato;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: 110.6%; /* 1.24425rem */
letter-spacing: 0.045rem;
text-transform: uppercase;
}

@media screen and (min-width: 768px) {
    .logo-div {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;
        width: 100px;
        height: 93px;
    }

    .main-header {
        gap: 1.8rem;
    }
}

@media screen and (max-width: 768px) {
    .main-header {
        padding: 0 20px;
        box-sizing: border-box;
        width: 100% !important;
        position: absolute;
        z-index: 30;
        background-color: transparent;
        margin-bottom: 50rem;
        padding-top: 2%;
    }

    .links {
        display: none;
    }

    .hamburger-menu {
        display: block;
    }

    .close-menu {
        display: block;
    }

    .links {
        width: 60%;
        display: none;
        position: absolute;
        top: 0%;
        right: -100px;
        flex-direction: column;
        background: rgba(51, 51, 51, 0.9);
        height: 100vh;
        padding: 80px 20px;
        z-index: 2000;
        opacity: 0;
        transition: 0.5s ease, opacity 0.5s ease;
        border-radius: 10px;
    }

    .links.show {
        opacity: 1;
        transition: 0.5s ease, opacity 0.5s ease;
        display: flex;
        right: 0;

    }

    .hamburger-menu.hide {
        display: none;
    }

    .close-button {
        display: block;
        background-color: transparent;
        border: none;
        color: #FFFFFF;
        font-size: 16px;
        cursor: pointer;
        margin-top: 20px;
        /* Adjust as needed */
    }
}