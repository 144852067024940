/* RoomUser.css */

.container {
    background-color: #000;
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .header button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
  }
  
  .headerTitle {
    color: #fff;
    font-size: 20px;
    margin-left: 10px;
  }
  
  .membersContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .membersText {
    color: #fff;
    margin-left: 10px;
  }
  
  .gamesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
    flex: 1;
  }
  
  .gameItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .gameImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  .gameName {
    color: #fff;
    margin-top: 10px;
  }
  